<template>
  <content-with-sidebar
      class="cws-container cws-sidebar-right blog-wrapper"
  >

    <!-- content -->
    <div class="blog-detail-wrapper" v-if="playlistDetail">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card
              _img-top
              _img-alt="Постер"
              _:title="`${playlistDetail.title}`"
          >
            <h1 class="d-flex">{{ playlistDetail.title }}
              <b-button
                  v-if="playlistDetail.url"
                  :href="playlistDetail.url"
                  variant="warning"
                  class="btn-icon ml-1"
                  size="sm"
              >
                <feather-icon icon="CastIcon"/>
              </b-button>
            </h1>

            <b-row>
              <div
                  class="blog-content"
                  v-html="playlistDetail.description"
              />
            </b-row>
            
            <b-row>
              <b-col
                  cols="12"
                  md="12"
                  class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
              >


                <b-card
                    :title="'Плейлист: ' + playlistDetail.title"
                    class="col-md-12 col-12"
                >
                  <b-overlay
                      :show="isLoadingPlaylist"
                      rounded="sm"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-tabs
                            pills
                        >
                          <!-- Tab: Песни (таблица) -->
                          <b-tab>
                            <template #title>
                              <feather-icon
                                  icon="Share2Icon"
                                  size="16"
                                  class="mr-0 mr-sm-50"
                              />
                              <span class="d-none d-sm-inline">Песни [{{ playlistDetail.tracks.length }}]</span>
                            </template>

                            <div class="my-1 py-25">
                              <b-table
                                  ref="refTracksListTable"
                                  :items="playlistDetail.tracks"
                                  responsive
                                  :fields="tableColumns"
                                  primary-key="id"
                                  show-empty
                                  empty-text="No tracks"
                                  class="position-relative"
                              >

                                <!-- Column: Client -->
                                <template #cell(artists)="data">
                                  <span v-for="artist in data.item.artists">
                                    <b-link
                                        v-if="artist.id"
                                        :to="{ name: 'artist-view', params: { id: artist.id }}"
                                        class="font-weight-bold"
                                    >
                                      {{ artist.name }}
                                    </b-link>
                                    <span                                      v-else>
                                      {{ artist.name }}
                                      <b-button
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="warning"
                                          class="btn-icon ml-1"
                                          size="sm"
                                          @click="addArtist(artist.name)"
                                      >
                                      <feather-icon icon="PlusIcon"/>
                                    </b-button>
                                    </span>
                                    /
                                  </span>
                                </template>

                                <!-- Column: duration -->
                                <template #cell(duration)="data">
                                  <span class="text-nowrap">
                                    {{ formatDuration(data.item.durationMs / 1000) }}
                                  </span>
                                </template>

                                <!-- Column: Client -->
                                <template #cell(actions)="data">
                                  <span class="text-nowrap">
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        v-if="!data.item.id"
                                        :variant="(data.item.artists && data.item.artists[0] && data.item.artists[0].id) ? 'success' : 'warning'"
                                        class="btn-icon ml-1"
                                        @click="addTrack(data.item)"
                                    >
                                      <feather-icon icon="PlusIcon"/>
                                    </b-button>
                                  </span>

                                </template>
                              </b-table>

                            </div>
                          </b-tab>

                        </b-tabs>
                      </b-col>
                    </b-row>
                  </b-overlay>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

  </content-with-sidebar>
</template>

<script>
import {
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
  BTab,
  BTabs,
  BSpinner,
  BOverlay,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {badWord, formatNumber, formatDate, formatDuration} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import router from "@/router";
import store from "@/store";
import musicStoreModule from "@/views/db-music/musicStoreModule";
import {onUnmounted, ref} from "@vue/composition-api";
import TrackRow from "@/views/db-music/TrackRow";
import {count} from "echarts/src/component/dataZoom/history";

export default {
  components: {
    TrackRow,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    BSpinner,
    BOverlay,
    BTable,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {}
  },
  methods: {
    count,
  },
  setup() {
    const STORE_MODULE_NAME = 'yandex'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, musicStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const playlistDetail = ref(null)
    const isLoadingPlaylist = ref(false)
    const disabledArtistId = ref(null)

    // Table Handlers
    const tableColumns = [
      {key: 'id', label: '#', sortable: false},
      {key: 'artists', sortable: false},
      {key: 'title', sortable: true},
      {key: 'duration', sortable: true},
      {key: 'actions'},
    ]

    const fetchPlaylistData = () => {
      let id = router.currentRoute.params.id
      let owner_id = router.currentRoute.params.owner_id
      store.dispatch('yandex/playlistView', {
        id,
        owner_id
      }).then(res => {
        playlistDetail.value = res.data
      })
    }

    const addArtist = (artist) => {
      blockData()
      store.dispatch(`yandex/createArtist`, {title: artist}).then(response => {
        unblockData()
      })
    }

    const addTrack = (track) => {
      let artist_id = null
      let artist_name = null
      if (track && track.artists && track.artists[0] && track.artists[0].id) {
        artist_id = track.artists[0].id
      }
      if (track && track.artists && track.artists[0] && track.artists[0].name) {
        artist_name = track.artists[0].name
      }

      blockData()

      if (!artist_id) {
        store.dispatch(`yandex/createArtist`, {title: artist_name}).then(response => {
          let new_artist_id = response.data.data.id
          store.dispatch('yandex/addTrack', {id: new_artist_id, queryParams: {title: track.title}})
              .then(response => {
                refreshData()
              })
        })
      } else {
        store.dispatch('yandex/addTrack', {id: artist_id, queryParams: {title: track.title}})
            .then(response => {
              refreshData()
            })
      }
    }

    const refreshData = () => {
      blockData()
      fetchPlaylistData()
      unblockData()
    }

    const blockData = () => {
      isLoadingPlaylist.value = true
    }

    const unblockData = () => {
      isLoadingPlaylist.value = false
    }

    fetchPlaylistData()

    return {
      formatDate,
      playlistDetail,
      isLoadingPlaylist,
      disabledArtistId,
      refreshData,
      addArtist,
      blockData,
      unblockData,
      tableColumns,
      formatNumber,
      formatDuration,
      addTrack,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
